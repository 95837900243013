import i18n from "@/i18n";
import ModelUser from "./ModelUser";

export default class ModelNewProject {
  name: string = "";
  dateStart: string = "";
  dateEnd: string = "";
  description: string = "";
  countryId: null | number = null;
  federalStateId: null | number = null;
  parentProjectId: number | null = null;
  county: string = "";
  provinceId: string = "";
  directionNameStart: string = "A";
  directionNameEnd: string = "B";
  directionPosStart: string = "000";
  directionPosEnd: string = "000";
  projectStatusId: number | any = {
    id: 1,
    name: "planning",
    label: i18n.t("project.statusOption.planning")
  };
  leaderId: string = "";
  leader: ModelUser | null = null;
  addInfo: string = "";
  projectTypeId: number | any = null;
  date_start_time: string = "00:00:00";
  date_end_time: string = "23:59:59";
  switchingReport: boolean = true;
  trafficReport: boolean = false;
  switchingMatrixReport: boolean = false;
  alertEmail: boolean = true;
  replaceBatteryEmailActive: boolean = true;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
