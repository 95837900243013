
/**
 *All the main projects list and sub-projects.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

import TableHeader from "@/components/tableHeader.vue";
import NewProjectModal from "@/components/project/ProjectList/newProjectModal.vue";
import SubProjectView from "@/components/project/ProjectList/SubProjectView.vue";
import UpdateProjectForm from "@/components/project/SingleProject/UpdateProjectForm.vue";
import { AxiosResponse } from "axios";
import Project from "@/model/Project";
import { TableFields } from "@/model/Tables/TableFields";
import confirmModal from "@/mixins/confirmModal/confirmModal";

// Pdf-report component generate a project overview list
import UserAccess from "@/model/User/UserAccess";
import PdfReportGeneratorModal from "@/components/project/SingleProject/Report/PdfReportGeneratorModal.vue";
import { ActionMessage } from "@/model/Messages/statusMessage";
import router from "@/router";

interface CurrentOpen {
  id: number;
  name: "";
}

@Component({
  name: "ProjectList",
  components: {
    TableHeader,
    NewProjectModal,
    SubProjectView,
    UpdateProjectForm,
    PdfReportGeneratorModal
  },
  computed: {
    ...mapGetters(["isUserAdmin", "isUserProjectManager", "isConstructionManager", "getUserAccess"]),
    ...mapGetters("projects", {
      mainProjectList: "GetAllMainProjects",
      activeMainProjectList: "GetAllActiveMainProjects",
      GetSubProjectsList: "GetSubProjectsList",
      GetActiveSubProjectsList: "GetActiveSubProjectsList",
      CURRENT_PROJECT_OPEN: "GetCurrentProjectOpen",
      CURRENT_MAIN_PROJECT_OPEN: "GetCurrentMainProjectOpen"
    })
  },
  methods: {
    ...mapActions("projects", {
      GetAllProjectsFromBackEnd: "GetAllProjectsFromBackEnd",
      UPDATE_PROJECT: "UpdateProject",
      updateMainProjectOpen: "updateMainProjectOpen",
      updateSubProjectOpen: "updateSubProjectOpen"
    })
  }
})
export default class ProjectList extends Mixins(confirmModal) {
  //Vuex
  readonly isUserAdmin!: boolean;
  readonly isUserProjectManager!: boolean;
  readonly isConstructionManager!: boolean;
  readonly getUserAccess!: UserAccess;
  readonly mainProjectList!: Array<Project>;
  readonly GetSubProjectsList!: Function;

  readonly activeMainProjectList!: Array<Project>;
  readonly GetActiveSubProjectsList!: Function;

  private readonly CURRENT_MAIN_PROJECT_OPEN!: CurrentOpen;
  private readonly CURRENT_PROJECT_OPEN!: CurrentOpen;

  private GetAllProjectsFromBackEnd!: Function;
  // testing
  private updateMainProjectOpen!: Function;
  private updateSubProjectOpen!: Function;

  private UPDATE_PROJECT!: Function;

  editMainProjectId: null | number = null;
  updateStatus: null | ActionMessage = null;

  waitingPdfGenerating: boolean = false;

  fields: Array<TableFields> = [
    {
      key: "name",
      sortable: true,
      label: this.translateTableLabel("name")
    },
    {
      key: "status",
      sortable: true,
      label: this.translateTableLabel("status")
    },
    {
      key: "dateStart",
      sortable: true,
      label: this.translateTableLabel("dateStart")
    },
    {
      key: "dateEnd",
      sortable: true,
      label: this.translateTableLabel("dateEnd")
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableLabel("actions")
    }
  ];
  mainProjectId: null | number = null;
  loading = false;
  translateTableLabel(label: string) {
    return this.$t(`projects.tableLabel.${label}`);
  }

  checkCurrentOpen() {
    if (!this.CURRENT_MAIN_PROJECT_OPEN) return;

    const $elMainProject: HTMLElement | null = document.querySelector(
      `[data-track-open=project-${this.CURRENT_MAIN_PROJECT_OPEN.id}]`
    );
    if ($elMainProject) {
      $elMainProject?.click();
      setTimeout(() => {
        const $elSubProject: HTMLElement | null = this.CURRENT_PROJECT_OPEN
          ? document.querySelector(`[data-track-open=project-${this.CURRENT_PROJECT_OPEN.id}]`)
          : null;

        $elSubProject ? $elSubProject.click() : null;
      }, 10);
    }
  }

  created() {
    this.loading = true;
    this.GetAllProjectsFromBackEnd().then((res: AxiosResponse) => {
      if (res.status !== 200) {
        this.updateStatus = {
          class: "danger",
          msg: res as any
        };
      }
      this.checkCurrentOpen();
      this.loading = false;
    });
  }
  archiveProject(project: Project) {
    this.updateStatus = null;
    this.confirm(
      (this as any).$t("projects.projectTable.confirmationModal.archive", {
        name: project.name
      }),
      async () => {
        this.loading = true;
        let formattedProject = new Project(project).formatToUpdate();
        formattedProject.projectStatusId = 4;
        let updatePorject = await this.UPDATE_PROJECT(formattedProject);
        if (updatePorject.status === 200) {
          this.updateStatus = {
            class: "success",
            msg: this.$t("projects.statusMsg.archiveSuccess").toString()
          };
        } else {
          this.handleStatus({
            class: "danger",
            msg: updatePorject.response.data.message
          });
        }
        this.checkCurrentOpen();
        this.loading = false;
      }
    );
  }

  handleStatus(status: ActionMessage) {
    this.updateStatus = status;
    this.mainProjectId = null;
    this.editMainProjectId = null;
  }

  handleCloseOfTheAlert() {
    this.updateStatus = null;
  }

  createMainProject() {
    this.mainProjectId = null;
    this.$bvModal.show("newProject");
  }

  AddSubproject(id: number) {
    this.mainProjectId = id;
    this.$bvModal.show("newProject");
  }

  openEditMainProject(mainProjectId: number) {
    this.editMainProjectId = mainProjectId;
    this.$bvModal.show("editMainProject");
  }

  showResultMessage() {
    this.updateStatus = {
      class: "success",
      msg: this.$t("projects.statusMsg.pdfReportgenerated").toString()
    };
  }

  handleOpen(row: any) {
    const isOpen = row.detailsShowing;
    const currentProjectId = row.item.id;
    const mainProjectId = row.item.parent?.id;

    row.toggleDetails();

    if (!isOpen) {
      if (!mainProjectId) {
        this.updateMainProjectOpen({
          id: currentProjectId,
          name: ""
        });
      }
      if (mainProjectId) {
        this.updateSubProjectOpen({
          id: currentProjectId,
          name: ""
        });
      }
    }

    if (isOpen && mainProjectId) {
      this.updateSubProjectOpen({
        id: null,
        name: ""
      });
    }
  }

  isCurrentUserPartOfThisProject(users: { id: number; uid: string }[]): boolean {
    const currentUserId = (router as any).app.$keycloak.tokenParsed.sub;
    const isUserPartOfThisProject = users.find((user) => user.uid === currentUserId);
    return !!isUserPartOfThisProject;
  }

  HandleCreateNewSubProject(id: number): void {
    this.mainProjectId = id;
    this.$bvModal.show("newProject");
  }

  get mainProjectListManipulated(): Array<any> {
    if(!this.isUserAdmin && !this.isUserProjectManager && !this.isConstructionManager) {
      return this.activeMainProjectList;
    } else {
      return this.mainProjectList;
    }
  }

  getSubProjectManipulated(id: any) {
    if(!this.isUserAdmin && !this.isUserProjectManager && !this.isConstructionManager) {
      return this.GetActiveSubProjectsList(id)

    } else {
      return this.GetSubProjectsList(id)
    }
  }

}
