
/**
 * Pdf-report of list of projects and subprojects with their devices.
 *
 * @author Rami Aqqad <r.aqqad@ibomade-technik.de>
 * @version 0.0.1
 *
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import jsPDF from "jspdf";
import { RowInput } from "jspdf-autotable";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import PdfReport, { NumberOfUsedDevicesInProject } from "@/model/ModelPdfReport";
import FileSaver from "file-saver";
// @ts-ignore
import JSZip from "jszip/dist/jszip";
import { Interval, ProjectProfile } from "@/interface/projectsInterface";

// interface SectionInfo {
//   Name: string;
//   Kilometer: string;
//   IDLinkeSeite: string;
//   IDRechteSeite: string;
// }

@Component({
  name: "PdfReportGeneratorModal",
  computed: {
    ...mapGetters("projects", {
      GetAllProjects: "GetAllProjects",
      GetAllMainProjects: "GetAllActiveMainProjects",
      GetSubProjectsList: "GetActiveSubProjectsList",
      GetCurrentProject: "GetCurrentProject",
      CurrentProjectSectionList: "GetSectionList"
    }),
    ...mapGetters(["isUserAdmin"]),
    ...mapGetters("ProjectProfilesStore", {
      ALL_PROJECT_PROFILES: "GetAllProfilesForCurrentProject"
    })
  },
  methods: {
    ...mapActions("ProjectProfilesStore", ["GET_ALL_PROJECT_PROFILES", "GET_PROFILE_DETAILS", "GET_PROFILE_INTERVALS"])
  }
})
export default class PdfReportGeneratorModal extends Vue {
  @Prop({ type: String, default: "projectList" }) reportType!: string;
  private readonly isUserAdmin!: boolean;
  /******Vuex *****/
  private readonly ALL_PROJECT_PROFILES!: Array<ProjectProfile>;
  private readonly CurrentProjectSectionList!: Array<Section>;

  private readonly GetAllProjects!: Array<Project>;
  private readonly GetAllMainProjects!: Array<Project>;
  private GetSubProjectsList!: Function;
  private GET_PROFILE_DETAILS!: Function;
  private GET_PROFILE_INTERVALS!: Function;

  // Profile report
  private readonly GetCurrentProject!: Project;

  reportGenerating: boolean = false;

  async createPdfProjectList() {
    let pdf: jsPDF;
    pdf = new jsPDF({ unit: "in", orientation: "portrait" });

    let pdfReport = new PdfReport();
    let subProjectRowEntryBody: RowInput[] = [];
    let numberOfUsedDevicesInMainProject, numberOfUsedDevicesInSubProject: NumberOfUsedDevicesInProject;
    let mainProjects = this.GetAllMainProjects;
    for (let i = 0; i < mainProjects.length; i++) {
      let subProjects = await this.GetSubProjectsList(mainProjects[i].id);
      numberOfUsedDevicesInMainProject = {
        numberOfLargeDisplay: 0,
        numberOfMediumDisplay: 0,
        numberOfSmallDisplay: 0,
        numberOfMeasure: 0,
        numberOfCamera: 0
      };

      for (let j = 0; j < subProjects.length; j++) {
        numberOfUsedDevicesInSubProject = {
          numberOfLargeDisplay: 0,
          numberOfMediumDisplay: 0,
          numberOfSmallDisplay: 0,
          numberOfMeasure: 0,
          numberOfCamera: 0
        };

        if (subProjects[j].type.name === "speedfunnel" || subProjects[j].type.name === "congestion_warning_cascaded") {
          let subSubProjects = await this.GetSubProjectsList(subProjects[j].id);
          for (let ssp = 0; ssp < subSubProjects.length; ssp++) {
            numberOfUsedDevicesInSubProject = pdfReport.calculateNumberOfUsedDevicesInSubProject(
              subSubProjects[ssp].sections,
              numberOfUsedDevicesInSubProject
            );
          }
        } else {
          numberOfUsedDevicesInSubProject = pdfReport.calculateNumberOfUsedDevicesInSubProject(
            subProjects[j].sections,
            numberOfUsedDevicesInSubProject
          );
        }

        subProjectRowEntryBody = pdfReport.buildSubProjectRowEntry(
          subProjects[j],
          numberOfUsedDevicesInSubProject,
          subProjectRowEntryBody
        );

        numberOfUsedDevicesInMainProject = pdfReport.calculateNumberOfUsedDevicesInMainProject(
          numberOfUsedDevicesInMainProject,
          numberOfUsedDevicesInSubProject
        );
      }

      let MainProjectStatTableData = pdfReport.buildMainProjectStatsTable(
        mainProjects[i],
        numberOfUsedDevicesInMainProject,
        subProjectRowEntryBody
      );
      pdf = await pdfReport.drawTable(pdf, MainProjectStatTableData, this.reportType, "projectStat");
      subProjectRowEntryBody = [];
    }
    pdf = pdfReport.drawReportFormPdfReport(pdf, "projectList", this.$d(new Date(), "repShort", "de").toString());

    return pdf;
  }

  async downloadZipFile() {
    this.reportGenerating = true;
    let pdf =
      this.reportType === "projectList" ? await this.createPdfProjectList() : await this.createPdfProfileReport();
    let zip = new JSZip();
    let today = this.$d(new Date(), "rep", "de").toString();
    let reportName = this.$t(`reports.${this.reportType}.reportName`);
    zip.file(`${reportName} ${today}.pdf`, pdf.output("blob"));
    zip.generateAsync({ type: "blob" }).then((content: any) => {
      FileSaver.saveAs(content, `${reportName} ${today}.zip`);
      // this.$emit('reportGenerated');
      this.resetAll();
    });
  }

  async createPdfProfileReport() {
    let pdf: jsPDF;
    pdf = new jsPDF({ unit: "in", orientation: "portrait" });
    let pdfReport = new PdfReport();
    const projectInfo: any = {
      projectName: this.GetCurrentProject.name,
      projectType: this.GetCurrentProject.type?.name,
      directionNameStart: this.GetCurrentProject.directionNameStart,
      directionNameEnd: this.GetCurrentProject.directionNameEnd
    };
    const projectInfoValues = [
      this.GetCurrentProject.name,
      `${this.GetCurrentProject.directionNameStart}->${this.GetCurrentProject.directionNameEnd}`,
      `m${this.$t("project.projectTypes." + this.GetCurrentProject.type?.name)}`,
      `${this.$d(new Date(this.GetCurrentProject.dateStart), "repShort", "de")} bis`,
      `${this.$d(new Date(this.GetCurrentProject.dateEnd), "repShort", "de")}`
    ];
    // Page 1 Project info
    pdf = await pdfReport.drawFirstPagePdfReport(pdf, projectInfoValues, projectInfo, this.reportType);
    pdf = await pdfReport.drawDisplayCharacters(pdf, projectInfo, this.reportType);

    pdf.addPage();
    pdfReport.drawPageForm(pdf, this.reportType, projectInfo);
    let profiles = this.ALL_PROJECT_PROFILES;
    for (let i = 0; i < profiles.length; i++) {
      let profileIntervalsTableData = await this.formatProfileIntervalsData(profiles[i]);
      pdf = await pdfReport.drawTable(pdf, profileIntervalsTableData, this.reportType, "intervals", projectInfo);
    }

    pdf.addPage();
    pdfReport.drawPageForm(pdf, this.reportType, projectInfo);
    let sectionProfileTableData = await this.formatSectionProfileTableData(this.CurrentProjectSectionList);
    pdf = await pdfReport.drawTable(pdf, sectionProfileTableData, this.reportType, "sectionProfiles", projectInfo);

    pdf = await pdfReport.drawPageNumber(pdf);
    return pdf;
  }

  async formatProfileIntervalsData(profile: ProjectProfile) {
    let profileIntervals = await this.GET_PROFILE_INTERVALS(profile.id);
    let intervals: RowInput[] = [];
    if (profileIntervals.data) {
      profileIntervals.data.forEach((interval: Interval) => {
        let namedDays = interval.weekdays
          .sort()
          .map((day: number) => this.$t(`project.section.displayInterval.schedulingDaysOptions.${day - 1}.day`));

        intervals.push([
          `${
            interval.holiday
              ? this.$t("project.section.displayInterval.holidayInterval")
              : this.$t("project.section.displayInterval.normalInterval")
          }`,
          `${
            interval.active
              ? this.$t("project.profiles.intervalTableLabel.active")
              : this.$t("project.profiles.intervalTableLabel.inactive")
          }`,
          `${interval.timeStart}`,
          `${interval.timeEnd}`,
          `${namedDays}`,
          `${interval.filename.replace(".bmp", "")}`
        ]);
      });
    }
    let head: RowInput[] = [
      [
        this.$t(`project.profiles.intervalTableLabel.type`),
        this.$t(`reports.profileList.intervalStatus`),
        this.$t(`project.profiles.intervalTableLabel.timeStart`),
        this.$t(`project.profiles.intervalTableLabel.timeEnd`),
        this.$t(`project.profiles.intervalTableLabel.weekdays`),
        this.$t(`reports.switchingStates.trafficSignsTitle`)
      ]
    ];
    let footer: RowInput[] = [];
    let title = profile.name;
    return {
      tableTitle: title,
      tableHeader: head,
      tableBody: intervals,
      tableFooter: footer
    };
  }

  async formatSectionProfileTableData(sections: Array<Section>) {
    let sectionProfiles: RowInput[] = [];

    let directionInfo: string = "";
    sections
      .filter((section) => section.type?.name === "display")
      .forEach((section) => {
        if (section.displayOneType) directionInfo += `${this.$t("reports.profileList.left")} `;
        if (section.boardOne) directionInfo += `(${section.boardOne})   `;
        if (section.displayTwoType) directionInfo += `${this.$t("reports.profileList.right")} `;
        if (section.boardTwo) directionInfo += `(${section.boardTwo})`;

        sectionProfiles.push([
          `${section.title}`,
          `${section.kilometrage}`,
          directionInfo,
          `${
            section.state.vsuSettings?.switchingProfile?.name ? section.state.vsuSettings?.switchingProfile?.name : ""
          }`
        ]);
        directionInfo = "";
      });

    let head: RowInput[] = [
      [
        this.$t(`reports.profileList.sectionTitle`),
        this.$t(`reports.profileList.kilometrage`),
        this.$t(`reports.profileList.direction`),
        this.$t(`reports.profileList.profileName`)
      ]
    ];
    let footer: RowInput[] = [];
    let title = this.$t(`reports.profileList.currentProfile`);
    return {
      tableTitle: title,
      tableHeader: head,
      tableBody: sectionProfiles,
      tableFooter: footer
    };
  }

  resetAll() {
    this.reportGenerating = false;
    this.$bvModal.hide("PdfReportGeneratorModal");
  }
}
