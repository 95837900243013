
/**
 *New Project form to create a new project .
 *Create a main project or a seb-project use the same form
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface countryState {
  name: string;
  id: number;
}
interface country {
  name: string;
  id: number;
  state: Array<countryState>;
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import Multiselect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import ModelNewProject from "@/model/ModelNewProject";
import DateToIsoString from "@/utils/DateToIsoString";
import ModelUser from "@/model/ModelUser";
import { DeviceEndPoint } from "@/services/InventoryService";

@Component({
  name: "NewProjectModal",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters("projects", {
      statusList: "GetAllStatus",
      typesList: "GetProjectTypes",
      countrysList: "GetAllAvailableCountrys",
      allUserOptions: "GetAllUserOption"
    })
  },
  methods: {
    ...mapActions("projects", {
      CreateNewProject: "CreateNewProject",
      getAllStatusList: "GetAllStatusFromBackEnd",
      getAllProjectTypes: "GetAllTypesFromBackEnd",
      GetAllCountrys: "GetCountryList",
      GetAllUser: "GetAllUsersFromTheProject"
    })
  }
})
export default class NewProjectModal extends Vue {
  // Vuex
  readonly statusList!: Array<object>;
  readonly typesList!: Array<object>;
  readonly countrysList!: Array<object>;
  private readonly allUserOptions!: Array<ModelUser>;

  private CreateNewProject!: Function;
  private getAllStatusList!: Function;
  private getAllProjectTypes!: Function;
  private GetAllCountrys!: Function;
  private GetAllUser!: Function;

  // Props
  @Prop({ type: Number, default: null }) mainProjectId!: number;
  // Data
  selected: null | object = null;
  typeSelected: number = 1;
  newProject: ModelNewProject = new ModelNewProject();

  localStartDate: string = "";
  localEndDate: string = "";
  localCountrySelected: null | country = null;
  localStateSelected: null | countryState = null;
  loading = false;

  LocalProjectTypesOptions: Array<any> = [];
  showMatrixReportButton: boolean = false;

  @Watch("typesList", { immediate: true })
  handleProjectTypes() {
    this.LocalProjectTypesOptions = this.typesList.map((item: any) => {
      item.label = this.$t(`projects.types.${item.name}`);
      return item;
    });
  }
  get isTheFormValid() {
    if (!this.mainProjectId) {
      return (
        this.$v.$invalid ||
        !this.newProject.projectTypeId ||
        !this.newProject.projectStatusId ||
        !this.localCountrySelected ||
        !this.localStartDate ||
        !this.localEndDate
      );
    } else {
      return (
        this.$v.$invalid ||
        !this.newProject.projectTypeId ||
        !this.newProject.projectStatusId ||
        !this.localCountrySelected ||
        !this.localStateSelected ||
        !this.localStartDate ||
        !this.localEndDate ||
        !this.newProject.leader
      );
    }
  }
  validations() {
    if (this.mainProjectId !== null) {
      return {
        newProject: {
          name: {
            required,
            minLength: minLength(1)
          },
          directionNameStart: {
            required,
            minLength: minLength(1)
          },
          directionNameEnd: {
            required,
            minLength: minLength(1)
          },
          directionPosStart: {
            required,
            minLength: minLength(1)
          },
          directionPosEnd: {
            required,
            minLength: minLength(1)
          },
          date_start_time: {
            required,
            minLength: minLength(1)
          },
          date_end_time: {
            required,
            minLength: minLength(1)
          },
          description: {
            required,
            minLength: minLength(1)
          }
        }
      };
    } else {
      return {
        newProject: {
          name: {
            required,
            minLength: minLength(1)
          },
          description: {
            required,
            minLength: minLength(1)
          }
        }
      };
    }
  }

  created() {
    this.getAllStatusList();
    this.getAllProjectTypes();
    this.GetAllUser();
  }

  mounted() {
    this.GetAllCountrys();
  }

  async createNewProject() {
    this.$emit("updateStatus", null);
    if (this.$v.$invalid) {
      return false;
    } else {
      if (this.localCountrySelected) {
        try {
          this.loading = true;
          this.newProject.parentProjectId = this.mainProjectId;
          // Set date
          this.newProject.dateStart = this.localStartDate;
          this.newProject.dateEnd = this.localEndDate;
          /*----------  Set the country and the state  ----------*/
          this.newProject.countryId = this.localCountrySelected.id;
          this.newProject.federalStateId = this.localStateSelected ? this.localStateSelected.id : null;

          // Set time to UTC
          if (this.newProject.projectTypeId.name !== "main_project") {
            let startDate: Date | string = new Date(`${this.localStartDate} ${this.newProject.date_start_time}`);
            let endDate: Date | string = new Date(`${this.localEndDate} ${this.newProject.date_end_time}`);
            this.newProject.dateStart = DateToIsoString(startDate);
            this.newProject.dateEnd = DateToIsoString(endDate);
          } else {
            this.newProject.dateStart = DateToIsoString(new Date(this.localStartDate));
            this.newProject.dateEnd = DateToIsoString(new Date(this.localEndDate));
          }

          this.newProject.projectStatusId = this.newProject.projectStatusId.id;
          this.newProject.projectTypeId = this.newProject.projectTypeId.id;
          this.newProject.leaderId = this.newProject.leader ? this.newProject.leader.id : "";

          let res = await this.CreateNewProject(this.newProject);
          this.loading = false;
          if (res.status === 201) {



            this.$emit("updateStatus", {
              class: "success",
              msg: this.$t("projects.statusMsg.createdSuccess")
            });
            this.$bvModal.hide("newProject");

          } else {
            this.$emit("updateStatus", {
              class: "success",
              msg: res
            });
            this.$bvModal.hide("newProject");
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "success",
            msg: error
          });
          this.loading = false;
          this.$bvModal.hide("newProject");
        }
      }
    }
  }

  resetForm() {
    this.selected = null;
    this.localStartDate = "";
    this.localEndDate = "";
    this.typeSelected = 1;
    this.localCountrySelected = null;
    this.localStateSelected = null;
    this.newProject = new ModelNewProject();
    this.newProject.projectTypeId = this.mainProjectId
      ? null
      : this.typesList.find((item: any) => item.name === "main_project");
  }

  filterTheProjectType() {
    if (this.mainProjectId) {
      return this.LocalProjectTypesOptions.filter((item: any) => item.name !== "main_project").sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    } else {
      return this.LocalProjectTypesOptions.filter((item: any) => item.name === "main_project");
    }
  }

  changeTypeToShowReportButton(projectType: any) {
    this.showMatrixReportButton = false;
    if (projectType.label === "WVZ") this.showMatrixReportButton = true;
  }
}
